import * as React from 'react';

const QUOTEBOT_REST_LOGIN = "https://api.quotes.mxblue.net.au/rest/login";

export function LoginPage(): JSX.Element {
    // Redirect straight to the REST login link
    window.location.href = QUOTEBOT_REST_LOGIN;

    return (
        <div />
    );
}

import * as React from 'react';
import { Link } from 'react-router-dom';
import style from './index.module.scss';

export function HomePage(): JSX.Element {
    return (
        <div className={style.links}>
            <Link to="/login">Login</Link>
            <Link to="/search">Search</Link>
        </div>
    );
}
